const PRODUCT_TYPES = [
  {
    name: 'dress',
    title: 'Dress',
    imageName: 'updatedDress',
    i18nKey: 'dress',
    category: 'dress'
  },
  {
    name: 'straplessdress',
    title: 'Strapless Dress',
    imageName: 'updatedStraplessDress',
    i18nKey: 'dressStrapless',
    category: 'dress'
  },
  {
    name: 'sleevedress',
    title: 'Sleeved Dress',
    imageName: 'updatedDressSleeve',
    i18nKey: 'dressSleeve',
    category: 'dress'
  },
  {
    name: 'alinedress',
    title: 'A-Line Dress',
    imageName: 'updatedDressALine',
    i18nKey: 'dressALine',
    category: 'dress'
  },
  {
    name: 'tunic',
    title: 'Tunic',
    imageName: 'updatedTunic',
    i18nKey: 'tunic',
    category: 'dress'
  },
  {
    name: 'top',
    title: 'Top',
    imageName: 'updatedTop',
    i18nKey: 'top',
    category: 'top'
  },
  {
    name: 'tshirt',
    title: 'T-Shirt',
    imageName: 'updatedTShirt',
    i18nKey: 'tShirt',
    category: 'top'
  },
  {
    name: 'shirt',
    title: 'Shirt',
    imageName: 'updatedShirt',
    i18nKey: 'shirt',
    category: 'top'
  },
  {
    name: 'sweater',
    title: 'Sweater',
    imageName: 'updatedSweater',
    i18nKey: 'sweater',
    category: 'top'
  },
  {
    name: 'jacketformal',
    title: 'Formal Jacket',
    imageName: 'jacketFormal',
    i18nKey: 'jacketFormal',
    category: 'jacket'
  },
  {
    name: 'jacketprotective',
    title: 'Protective Jacket',
    imageName: 'jacketProtective',
    i18nKey: 'jacketProtective',
    category: 'jacket'
  },
  {
    name: 'coat',
    title: 'Coat',
    imageName: 'updatedCoat',
    i18nKey: 'coat',
    category: 'coat'
  },
  {
    name: 'pants',
    title: 'Pants',
    imageName: 'updatedPants',
    i18nKey: 'pants',
    category: 'pants'
  },
  {
    name: 'shorts',
    title: 'Shorts',
    imageName: 'updatedShorts',
    i18nKey: 'shorts',
    category: 'pants'
  },
  {
    name: 'skirt',
    title: 'Skirt',
    imageName: 'updatedSkirt',
    i18nKey: 'skirt',
    category: 'skirt'
  },
  {
    name: 'bag',
    title: 'Bag',
    imageName: 'updatedBag',
    i18nKey: 'bag',
    category: 'bag'
  },
  {
    name: 'suitcase',
    title: 'Suitcase',
    imageName: 'updatedSuitcase',
    i18nKey: 'suitcase',
    category: 'bag'
  },
  {
    name: 'backpack',
    title: 'Backpack',
    imageName: 'updatedBackpack',
    i18nKey: 'backpack',
    category: 'bag'
  },
  {
    name: 'briefcase',
    title: 'Briefcase',
    imageName: 'updatedBriefcase',
    i18nKey: 'briefcase',
    category: 'bag'
  },
  {
    name: 'clutch',
    title: 'Clutch',
    imageName: 'updatedClutch',
    i18nKey: 'clutch',
    category: 'clutch'
  },
  {
    name: 'wallet',
    title: 'Wallet',
    imageName: 'updatedWallet',
    i18nKey: 'wallet',
    category: 'wallet'
  }
]

export default PRODUCT_TYPES
