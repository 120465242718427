<template>
  <ul class="guidelines">
    <li v-for="(item, index) in guideItems" :key="index">
      {{ item.mainText | t }}
      <ul v-if="item.subTexts" class="guidelines-subtext">
        <li v-for="(subText, subIndex) in item.subTexts" :key="subIndex">
          {{ subText | t }}
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'GuideLines',
  data () {
    return {
      guideItems: [
        {
          mainText: 'publicPage.generalGuideItems.item1.mainText',
          subTexts: [
            'publicPage.generalGuideItems.item1.subText1',
            'publicPage.generalGuideItems.item1.subText2',
            'publicPage.generalGuideItems.item1.subText3'
          ]
        },
        { mainText: 'publicPage.generalGuideItems.item2' },
        { mainText: 'publicPage.generalGuideItems.item3' },
        { mainText: 'publicPage.generalGuideItems.item4' },
        { mainText: 'publicPage.generalGuideItems.item5' },
        { mainText: 'publicPage.generalGuideItems.item6' },
        { mainText: 'publicPage.generalGuideItems.item7' }
      ]
    }
  },
  methods: {
    hasMainText () {
      return true
    }
  }
}
</script>

<style lang="less" scoped>
@import '~/assets/styles/import.less';

.guidelines {
  margin-left: 20px;
  list-style: disc;

  li {
    padding: 5px;
    color: @lightBlack;
    font-size: 14px;
  }

  .guidelines-subtext {
    padding-left: 15px;
    list-style: circle;
  }
}
</style>
