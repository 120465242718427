<template>
  <div class="language-button-wrapper">
    <button
      class="language-button"
      @click="toggleOpen"
    >
      {{ 'publicPage.language' | t }}
    </button>
    <div
      v-show="isOpen"
      class="overlay"
      @click="toggleOpen"
    />
    <ul
      v-show="isOpen"
      class="dropdown-menu"
    >
      <li @click="setLanguage('en')">
        English
      </li>
      <li @click="setLanguage('ja')">
        日本語
      </li>
      <li @click="setLanguage('ko')">
        한국어
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'LanguageSelector',
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    toggleOpen () {
      this.isOpen = !this.isOpen
    },
    setLanguage (code) {
      localStorage.setItem('measuringGuideLocale', code)
      location.reload()
    }
  }
}
</script>

<style lang="less" scoped>
@import '~/assets/styles/import.less';

.language-button-wrapper {
  position: relative;
  display: inline-block;
}

.language-button {
  padding: 4px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: medium;
  line-height: 20px;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  background-color: #f5f5f5;
  background-image: linear-gradient(to bottom, @white, #e6e6e6);
  cursor: pointer;

  &::after {
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 4px solid @black;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    vertical-align: middle;
    content: "";
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  min-width: 160px;
  margin-top: 2px;
  padding: 5px 0;
  border: @baseBorder;
  border-radius: 6px;
  background-color: @white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;

  li {
    padding: 3px 20px;
    line-height: 20px;
  }
}
</style>
