<template>
  <div class="content-wrapper">
    <div class="text-wrapper">
      <div class="required">
        <h3>{{ 'publicPage.requiredMeasurements' | t }}</h3>
        <div
          v-for="measurement in getMeasurements(productType.i18nKey, 'measurementsRequired')"
          :key="`${productType.i18nKey}-${measurement}`"
          class="measurements"
        >
          <p class="measurement-number">
            {{ `itemGuide.${productType.i18nKey}.measurementsRequired.${measurement}.number` | t }}
          </p>
          <p class="measurement-content">
            <span class="required-name">{{
              `itemGuide.${productType.i18nKey}.measurementsRequired.${measurement}.title` | t
            }}</span>
            <span class="text">{{
              `itemGuide.${productType.i18nKey}.measurementsRequired.${measurement}.text` | t
            }}</span>
          </p>
        </div>
      </div>
      <div class="optional">
        <h3>{{ 'publicPage.optionalMeasurements' | t }}</h3>
        <div
          v-for="measurement in getMeasurements(productType.i18nKey, 'measurementsOptionals')"
          :key="`${productType.i18nKey}: ${measurement}`"
          class="measurements"
        >
          <p class="optional-measurement-number">
            {{ `itemGuide.${productType.i18nKey}.measurementsOptionals.${measurement}.number` | t }}
          </p>
          <p class="measurement-content">
            <span class="optional-name">{{
              `itemGuide.${productType.i18nKey}.measurementsOptionals.${measurement}.title` | t
            }}</span>
            <span class="text">{{
              `itemGuide.${productType.i18nKey}.measurementsOptionals.${measurement}.text` | t
            }}</span>
            <ul
              v-if="hasAdditionalTexts(productType.i18nKey, measurement)"
              class="additional-text"
            >
              <li>
                {{ `itemGuide.${productType.i18nKey}.measurementsOptionals.${measurement}.text2` | t }}
              </li>
              <li>
                {{ `itemGuide.${productType.i18nKey}.measurementsOptionals.${measurement}.text3` | t }}
              </li>
            </ul>
          </p>
        </div>
      </div>
    </div>
    <div class="product-image-wrapper">
      <img
        :class="`${productType.category}-wrapper`" :alt="productType.imageName"
        :src="`${assetsUrl}/${productType.imageName}.jpg`"
      >
    </div>
  </div>
</template>

<script>
import { ASSETS_BASE_URL } from '~/lib/urls'
import { Product, Measurement } from '~/lib/constants'

export default {
  name: 'ProductType',
  props: {
    productType: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      assetsUrl: ASSETS_BASE_URL
    }
  },
  methods: {
    getMeasurements (itemName, measurementType = 'measurementsRequired') {
      const measurements = this.$t(`itemGuide.${itemName}.${measurementType}`)
      const sortedMeasurements = Object.keys(measurements)
        .sort((a, b) => {
          const letterA = measurements[a].number
          const letterB = measurements[b].number
          return letterA.localeCompare(letterB)
        })
      return sortedMeasurements
    },
    hasAdditionalTexts (itemName = String, measurement = String) {
      return Array.from([Product.Briefcase, Product.Bag]).includes(itemName) && measurement === Measurement.HandleDrop
    }
  }
}
</script>

<style lang="less" scoped>
@import '~/assets/styles/import.less';

.content-wrapper {
  display: flex;
  justify-content: space-between;

  .text-wrapper {
    width: 80%;

    h3 {
      margin: 10px 0;
      color: @black;
      font-weight: bold;
      font-size: 14px;
      text-transform: uppercase;
    }

    .measurements {
      display: flex;
      margin: 20px 0;
      font-weight: 300;
      font-size: 14px;
    }

    .measurement-number,
    .required-name {
      color: @blue;
      font-weight: bold;
    }

    .required-name,
    .optional-name {
      margin-right: 5px;
      font-weight: bold;
    }

    .measurement-number,
    .optional-measurement-number {
      width: 8%;
    }

    .measurement-content {
      width: 85%;
    }

    .optional-measurement-number {
      color: @black;
      font-weight: bold;
    }

    .additional-text {
      padding-left: 15px;
      list-style: disc;
    }
  }

  .product-image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #cdcdcd;
    background-color: @white;

    img {
      padding: 10px 10px;
      object-fit: contain;
    }

    .coat-wrapper {
      width: 350px;
      height: 600px;
    }

    .clutch-wrapper,
    .wallet-wrapper {
      width: 250px;
    }

    .bag-wrapper,
    .pants-wrapper {
      width: 250px;
      height: 350px;
    }

    .dress-wrapper {
      width: 200px;
      height: 300px;
      margin: 30px;
    }

    .jacket-wrapper,
    .top-wrapper {
      width: 350px;
      height: 500px;
    }

    .skirt-wrapper {
      width: 150px;
      height: 250px;
      margin: 30px;
    }
  }
}
</style>
